import * as React from "react";
import styled from "styled-components";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import SubTitle from "../components/sub-title/sub-title";
import Title from "../components/title/title";

const Main = styled.main`
  color: #232129;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title={`Not found`}></SEO>
      <Main>
        <Title>Page not found</Title>
        <SubTitle>
          It looks like the page your were looking for doesn't exist :(
        </SubTitle>
      </Main>
    </Layout>
  );
};

export default NotFoundPage;
